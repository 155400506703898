import { useAssetIcon } from '@blink/assets';
import React, { FC } from 'react';

import { concatClasses } from '../../utils';
import { useAssetIconStyles } from './AssetIcon.style';

type AssetIconProps = React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> & {
    assetName: string;
    className?: string;
};

export const AssetIcon: FC<AssetIconProps> = ({ assetName, className, ...props }) => {
    const classes = useAssetIconStyles();
    const icon = useAssetIcon(assetName?.toUpperCase());
    const imageClassName = concatClasses(className || '', classes.root || '');

    if (!icon) {
        return <div className={imageClassName} />;
    }

    return <img className={imageClassName} src={icon} alt={assetName} {...props} />;
};
