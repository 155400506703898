import { put, select, takeLatest } from 'redux-saga/effects';
import { WALLET } from '@blink/components/src/types';
import { AbstractWallet, LOCAL_STORAGE, LocalStorage } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Message } from '@blink/components/src/constants/messages';
import { Errors } from '@blink/components/src/constants/errors';
import {
    METAMASK_ERRORS,
    NETWORK,
    NETWORK_CHAIN_TO_NETWORK,
    UNSUPPORTED_NETWORK_ID,
} from '@blink/components/src/constants/global';

import {
    SetWalletConnectType,
    addActiveWallet,
    addNetwork,
    addWallets,
    addWalletsSaga as addWalletsSagaAction,
    removeWallets,
    setWalletConnect,
} from '../store/wallets/actions';
import {
    ADD_WALLETS_SAGA,
    ADD_WALLET_CONNECT,
    CHANGE_NETWORK_SAGA,
    RECONNECT_IF_WALLET_AVAILABLE_SAGA,
    DISCONNECT_WALLET_SAGA,
} from '../store/wallets/constants';
import { Store } from 'src/store';
import { hideModalAction } from '../store/modals/actions';
import { addNetworksErrorAction, addWalletsErrorAction } from 'src/store/errors/actions';
import { ActiveWallet } from 'src/store/wallets';

function* reconnectIfWalletAvailableSaga(): any {
    try {
        const walletType: WALLET | null = LocalStorage.getItem(LOCAL_STORAGE.WALLET) as WALLET;
        const walletInstance = walletMap.get(walletType);
        const isWalletAvailable = walletType && walletInstance && walletInstance.isAvailable();
        if (isWalletAvailable) {
            const isWalletConnected = yield walletInstance.isConnected();
            if (isWalletConnected) {
                yield put(addWalletsSagaAction(walletType));
                const connectedDapp: string =
                    LocalStorage.getItem(LOCAL_STORAGE.CONNECTED_DAPP) || '';
                if (connectedDapp.length > 0) {
                    yield put(setWalletConnect(JSON.parse(connectedDapp)));
                }
            }
        }
    } catch (e: any) {
        yield put(addWalletsErrorAction({ message: e.message || Errors.ADD_WALLETS }));
        new Error(e);
    }
}

function* addWalletsSaga({ payload: type }: any): any {
    try {
        const walletConnectAddress: string =
            LocalStorage.getItem(LOCAL_STORAGE.WALLET_CONNECT_ADDRESS) || '';
        const walletType: string = LocalStorage.getItem(LOCAL_STORAGE.WALLET) || '';
        if (
            walletConnectAddress.length > 0 &&
            walletType === WALLET.WALLET_CONNECT &&
            type === WALLET.WALLET_CONNECT
        ) {
            const walletInfo: ActiveWallet = {
                address: walletConnectAddress,
                type: WALLET.WALLET_CONNECT,
            };
            yield put(addActiveWallet(walletInfo));
            return;
        }
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
        const wallets = yield wallet.connect();
        const address = wallets[0];
        yield put(addWallets({ wallets, type }));
        yield put(addActiveWallet({ address, type }));
        LocalStorage.setItem(LOCAL_STORAGE.WALLET, type);
        if (type === WALLET.WALLET_CONNECT) {
            LocalStorage.setItem(LOCAL_STORAGE.WALLET_CONNECT_ADDRESS, address);
        }
        const walletChainId = yield wallet.getChainId();
        const chainId = parseInt(walletChainId).toString();
        const network: NETWORK =
            NETWORK_CHAIN_TO_NETWORK.get(chainId) || (UNSUPPORTED_NETWORK_ID as NETWORK);
        LocalStorage.setItem(LOCAL_STORAGE.NETWORK, network);
        yield put(addNetwork(network));
    } catch (e: any) {
        yield put(addNetworksErrorAction({ message: e.message || Errors.ADD_NETWORK }));
        console.warn(e);
    }
}

function* changeNetworkSaga({ payload: network }: any): any {
    const type: WALLET = yield select((state: Store) => state.wallets.active.type);
    const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
    try {
        yield wallet.changeNetwork(network);
        yield put(addNetwork(network));
        yield put(hideModalAction());
    } catch (e: any) {
        if (e?.code !== METAMASK_ERRORS.USER_REJECTED) {
            yield put(
                addWalletsErrorAction({ message: e.message || Message.CHANGE_NETWORK_ERROR }),
            );
            console.warn(e);
        }
    }
}

function* disconnectWalletSaga() {
    const type: WALLET = yield select((state: Store) => state.wallets.active.type);
    const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
    yield wallet.disconnect();
    yield put(removeWallets());
    LocalStorage.setItem(LOCAL_STORAGE.WALLET, '');
    LocalStorage.setItem(LOCAL_STORAGE.CONNECTED_DAPP, '');
    LocalStorage.setItem(LOCAL_STORAGE.WALLET_CONNECT_ADDRESS, '');
    LocalStorage.setItem(LOCAL_STORAGE.WALLET_CONNECT_MARGIN_ADDRESS, '');
}

function* addWalletConnectSaga(): any {
    const connectedDapp: SetWalletConnectType = yield select(
        (state: Store) => state.wallets.walletConnect?.connectedDapp,
    );
    LocalStorage.setItem(LOCAL_STORAGE.CONNECTED_DAPP, JSON.stringify(connectedDapp));
}

export function* walletWatcher() {
    yield takeLatest(ADD_WALLETS_SAGA, addWalletsSaga);
    yield takeLatest(RECONNECT_IF_WALLET_AVAILABLE_SAGA, reconnectIfWalletAvailableSaga);
    yield takeLatest(DISCONNECT_WALLET_SAGA, disconnectWalletSaga);
    yield takeLatest(CHANGE_NETWORK_SAGA, changeNetworkSaga);
    yield takeLatest(ADD_WALLET_CONNECT, addWalletConnectSaga);
}
