import React, { FC } from 'react';
import { AssetWithIcon, ButtonStroke } from '@blink/components';
import { ReactComponent as LogoIcon } from '@blink/assets/src/icons/logoInCircle.svg';
import { Progress } from '@blink/components/src/Progress/Progress';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { shortFormatNumber } from '@blink/components/src/utils';
import { useNavigate } from 'react-router-dom';

import { useAssetsToLendRowsStyle } from './AssetsToLendItemRow.style';
import { showModalAction } from 'src/store/modals/actions';
import { ModalName } from 'src/store/modals/constants';
import { Store } from 'src/store';
import { Agreement, ROLE } from 'src/api/borrow';
import { getAgreementsAction } from 'src/store/agreements/actions';

type AssetsToLendRowsProps = {
    allItems: Agreement[];
    itemIndex: number;
    role: ROLE;
};

export const AssetsToLendItemRow: FC<AssetsToLendRowsProps> = ({ itemIndex, allItems, role }) => {
    const classes = useAssetsToLendRowsStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const agreement = allItems[itemIndex];
    const { address, title, leverage, apy, totalDeposited, totalDepositThreshold } = agreement;
    const formattedApyRewards = Number(apy).toFixed(2);
    const currentApy = Number(formattedApyRewards);
    const totalSupply = shortFormatNumber({ amount: Number(totalDepositThreshold) });
    const walletAddress = useSelector((state: Store) => state.wallets.active.address);

    const refreshAgreements = async () => dispatch(getAgreementsAction(role));

    const handleSupplyClick = () => {
        dispatch(
            showModalAction({
                modalName: ModalName.LIQUIDITY_MODAL,
                modalTitle: 'Supply Liquidity',
                modalProps: {
                    tokenSymbol: leverage.symbol,
                    balance: leverage.userBalance,
                    currentApy,
                    totalDeposited: shortFormatNumber({ amount: Number(totalDeposited) }),
                    totalDepositThreshold: totalSupply,
                    agreementAddress: address,
                    tokenAddress: leverage.address,
                    isSupply: true,
                    decimals: leverage.decimals,
                    refresh: refreshAgreements,
                    poolUtilization: agreement!.poolUtilization,
                },
            }),
        );

        return;
    };

    const supplyProgress = Math.round(
        (Number(totalDeposited) / Number(totalDepositThreshold)) * 100,
    );

    const handleDetails = () => {
        navigate(`/pool/${address}?role=lender`);
        return;
    };

    return (
        <>
            <tr className={classes.tr}>
                <td className={classes.firstTd}>{title}</td>
                <td>
                    <AssetWithIcon assetName={leverage.symbol} />
                </td>
                <td>
                    <div className={classes.progressWrapper}>
                        <div>{`${totalSupply} ${leverage.symbol}`}</div>
                        <Progress percent={supplyProgress} />
                    </div>
                </td>
                <td>
                    <div>{currentApy}%</div>
                    <div className={classes.supplyApyGroup}>
                        <div>{`${Number(apy)}%`}</div>
                        <LogoIcon />
                    </div>
                </td>
                <td>
                    <div>{`${leverage.userBalance} ${leverage.symbol}`}</div>
                </td>
                <td>
                    <div className={classes.buttonWrapper}>
                        <ButtonStroke
                            isPrimary={true}
                            size={ButtonSize.md}
                            className={classes.supplyButton}
                            onClick={handleSupplyClick}
                            disabled={!walletAddress}
                        >
                            {'Supply'}
                        </ButtonStroke>
                        <ButtonStroke
                            size={ButtonSize.md}
                            className={classes.detailsButton}
                            onClick={handleDetails}
                        >
                            Details
                        </ButtonStroke>
                    </div>
                </td>
            </tr>
        </>
    );
};
