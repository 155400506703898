import React, { FC, useEffect, useMemo } from 'react';
import { Tooltip } from 'antd';
import { color, SkeletonRow } from '@blink/components';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ClockIcon } from '@blink/assets/src/icons/clock.svg';

import { useUserSuppliesSectionStyle } from '../../UserSupplies/UserSuppliesSection/UserSuppliesSection.style';
import { AssetsToLendItemRow } from '../AssetsToLendItemRow';
import { useAssetsToLendSectionStyle } from './AssetsToLendSection.style';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { Store } from '../../../store';
import { FixedRateMenuKey } from '../../FixedRateMenu/constants';
import { getAgreementsAction } from 'src/store/agreements/actions';
import { ROLE } from 'src/api/borrow';
import { UserSuppliesEmpty } from '../../UserSupplies';

type AssetsToLendSectionProps = {
    selectedRate: FixedRateMenuKey;
};

export const AssetsToLendSection: FC<AssetsToLendSectionProps> = ({ selectedRate }) => {
    const externalClasses = useUserSuppliesSectionStyle();
    const classes = useAssetsToLendSectionStyle();
    const { agreements, selectedAgreement, agreementsLoading, wallet } = useSelector(
        (state: Store) => ({
            agreements: state.agreements.agreements,
            selectedAgreement: state.agreements.selectedAgreement,
            agreementsLoading: state.agreements.agreementsLoading,
            wallet: state.wallets.active.address,
        }),
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (
            (!agreementsLoading && (agreements.length === 0 || agreements[0].address === '')) ||
            selectedAgreement !== ROLE.LENDER
        ) {
            dispatch(getAgreementsAction(ROLE.LENDER));
        }
    }, [wallet]);

    const getContent = () => {
        if (agreementsLoading) {
            return (
                <>
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                </>
            );
        }

        const indexes = [];
        for (const [i, item] of agreements.entries()) {
            if (item.role === ROLE.LENDER) indexes.push(i);
        }

        return indexes.map((index) => (
            <AssetsToLendItemRow
                key={index}
                itemIndex={index}
                allItems={agreements}
                role={ROLE.LENDER}
            />
        ));
    };

    const isEmpty = useMemo(
        () => !agreementsLoading && (agreements.length === 0 || agreements[0].address === ''),
        [agreements, agreementsLoading],
    );

    return (
        <div className={externalClasses.root}>
            <div className={externalClasses.title}>{'Assets to Supply'}</div>
            {isEmpty ? (
                <div>
                    <UserSuppliesEmpty
                        customTitle={'You don’t have any whitelisted pools yet.'}
                        customSubtitle={
                            'Please check back later or contact support if you need help with accessing pools.'
                        }
                        icon={ClockIcon}
                    />
                </div>
            ) : (
                <div className={externalClasses.tableWrapper}>
                    <table className={externalClasses.table}>
                        <thead>
                            <tr className={externalClasses.tr}>
                                <th className={classes.asset}>Pool</th>
                                <th className={classes.asset}>Asset</th>
                                <th className={classes.totalSupplyWrapper}>Total Supply</th>
                                <th>
                                    <div>
                                        <span>{`Supply APY`}</span>{' '}
                                    </div>
                                </th>
                                {selectedRate === FixedRateMenuKey.PROFIT_SHARING && (
                                    <th>
                                        <div>
                                            <Tooltip
                                                className={classes.supply}
                                                rootClassName={classes.tooltipSupplyRoot}
                                                title='Sample'
                                                color={color.background.secondary}
                                            >
                                                <span>Profit Sharing</span>{' '}
                                                <InfoIcon className={classes.icon} />
                                            </Tooltip>
                                        </div>
                                    </th>
                                )}
                                <th>Your Wallet</th>
                                <th className={classes.buttons} />
                            </tr>
                        </thead>
                        <tbody>{getContent()}</tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
