import { formatUnits, parseUnits } from 'ethers';

import { Agreement, Token, UserBorrow } from 'src/api/borrow';
import { UserBorrowItem } from 'src/components/Borrow/UserBorrows';

export const getTransformedUserBorrow = (object: UserBorrow): UserBorrowItem => {
    return {
        address: object.address,
        id: object.leverage.id,
        apy: object.apy || '0',
        leverageAddress: object.leverage.address,
        name: object.leverage.name,
        symbol: object.leverage.symbol,
        decimals: object.leverage.decimals,
        amount: object.leverage.amount,
        openedAt: object.openedAt,
        maintenance: object.maintenance,
        history: object.history,
    };
};

export const getTransformedUserBorrowList = (objectsArray: UserBorrow[]): UserBorrowItem[] => {
    return objectsArray
        .sort((a, b) => {
            return new Date(b.openedAt).getTime() - new Date(a.openedAt).getTime();
        })
        .map((object: UserBorrow) => getTransformedUserBorrow(object));
};

export const mapAgreementData = (arr1: Agreement[], arr2: Token[]) => {
    return arr1.map((item) => {
        const agreementMatches = arr2.filter((subItem) => subItem.id === item.leverageTokenId);

        return {
            ...item,
            leverage: agreementMatches[0],
        };
    });
};

export const compareBigNumbers = (numStr1: string, numStr2: string, decimals: number) => {
    const bigNumber1 = parseUnits(numStr1, decimals);
    const bigNumber2 = parseUnits(numStr2, decimals);

    if (bigNumber1 > bigNumber2) {
        return 1;
    } else if (bigNumber1 < bigNumber2) {
        return -1;
    } else {
        return 0;
    }
};

export const subtractFixedDecimals = (numStr1: string, numStr2: string, decimals: number) => {
    const bigNumber1 = parseUnits(numStr1, decimals);
    const bigNumber2 = parseUnits(numStr2, decimals);

    const result = bigNumber1 - bigNumber2;
    return formatUnits(result, decimals);
};

export const sumFixedDecimals = (numStr1: string, numStr2: string, decimals: number) => {
    const bigNumber1 = parseUnits(numStr1, decimals);
    const bigNumber2 = parseUnits(numStr2, decimals);

    const result = bigNumber1 + bigNumber2;
    return formatUnits(result, decimals);
};
