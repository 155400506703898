import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import { Contract, ethers, formatUnits } from 'ethers';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Errors } from '@blink/components/src/constants/errors';

import { Store } from '../store';
import {
    Agreement,
    AgreementDetails,
    Token,
    getAgreementDetails,
    getAgreements,
    getTokens,
    ROLE,
    Metadata,
    State,
} from '../api/borrow';
import IERCAbi from '../abi/IERCAbi.json';
import AgreementABI from '../abi/AgreementABI.json';
import { agreementErrorAction } from 'src/store/errors/actions';
import { mapAgreementData } from 'src/utils/helpers';
import { GET_AGREEMENTS } from 'src/store/agreements/constants';
import { setAgreementsAction, setAgreementsLeadingFalseAction } from 'src/store/agreements/actions';
import { setTokensAction } from 'src/store/tokens/actions';

function* getAgreementsSaga(action: { type: string; payload: ROLE }): Generator<any, void, any> {
    const agreementRole = action.payload;

    try {
        const {
            type,
            blockchainName,
            walletAddress,
        }: { type: any; blockchainName: any; walletAddress: string } = yield select(
            (state: Store) => ({
                type: state.wallets.active.type,
                blockchainName: state.wallets.network,
                walletAddress: state.wallets.active.address,
            }),
        );
        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;

        if (!wallet) {
            yield put(setAgreementsLeadingFalseAction());
        }

        if (wallet) {
            const walletProvider: any = yield wallet.getProvider();

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );
            const signer: ethers.Signer = yield provider.getSigner();

            const [tokens, agreements]: [Token[], Agreement[]] = yield all([
                call(getTokens),
                call(getAgreements),
            ]);

            if (!agreements) {
                yield put(setAgreementsLeadingFalseAction());
                return;
            }

            const excludedAddress = [
                '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
                '0x0000000000000000000000000000000000000348',
                '0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB',
            ];

            const filteredTokens = tokens.filter(
                (token) => !excludedAddress.includes((token.address || '').trim()),
            );

            let filteredAgreements = agreements;

            if (agreementRole) {
                filteredAgreements = agreements.filter(
                    (agreement) => agreement.role === agreementRole,
                );
            }

            if (agreementRole && !filteredAgreements) {
                yield put(agreementErrorAction({ message: Errors.AGREEMENTS }));
                return;
            }

            const tokensByAgreements = filteredAgreements.map((agreement) => {
                const token = filteredTokens.find(
                    (filteredToken) => filteredToken.id === agreement.leverageTokenId,
                );
                token!.agreementAddress = agreement.address;
                return token as Token;
            });

            if (!tokensByAgreements) {
                yield put(agreementErrorAction({ message: Errors.TOKENS }));
                return;
            }

            const tokenBalances = yield all(
                tokensByAgreements.map((token) =>
                    call(function* () {
                        try {
                            const tokenContract = new Contract(
                                token.address as string,
                                IERCAbi,
                                signer,
                            );

                            const userBalance = yield tokenContract.balanceOf(walletAddress);

                            const poolBalance = yield tokenContract.balanceOf(
                                token.agreementAddress,
                            );

                            token.userBalance = formatUnits(userBalance as string, token.decimals);
                            token.poolBalance = formatUnits(poolBalance as string, token.decimals);
                        } catch (e: any) {
                            yield put(
                                agreementErrorAction({ message: e.message || Errors.TOKENS }),
                            );
                            console.log(e);
                        }
                        return token;
                    }),
                ),
            );

            yield put(setTokensAction(tokenBalances as Token[]));

            const resultAgreements = mapAgreementData(filteredAgreements, tokensByAgreements);

            yield all(
                resultAgreements.map((agreement) =>
                    call(function* () {
                        const agreementAddress = agreement.address || '';

                        try {
                            const agreementContract = new Contract(
                                agreementAddress,
                                AgreementABI,
                                signer,
                            );

                            const [metadata, state]: [Metadata, State] =
                                yield agreementContract.info();

                            agreement.collaterals = Array.from(metadata.collaterals);
                            agreement.lenders = Array.from(metadata.lenders);
                            agreement.borrowers = Array.from(metadata.borrowers);
                            agreement.tokens = Array.from(state.tokens);

                            const [
                                totalPosition,
                                interestAccrued,
                                totalBorrowed,
                                totalDeposited,
                                agreementDetails,
                            ]: [bigint, bigint, bigint, bigint, AgreementDetails] = yield all([
                                agreementContract.balanceOf(walletAddress),
                                agreementContract.pendingRewards(walletAddress),
                                agreementContract.totalBorrowed(),
                                agreementContract.totalDeposited(),
                                call(getAgreementDetails, agreementAddress),
                            ]);

                            agreement.totalPosition = formatUnits(
                                totalPosition,
                                agreement.leverage.decimals,
                            );
                            agreement.poolBalance = agreement.leverage.poolBalance;
                            agreement.interestAccrued = formatUnits(
                                interestAccrued,
                                agreement.leverage.decimals,
                            );
                            agreement.totalBorrowed = formatUnits(
                                totalBorrowed,
                                agreement.leverage.decimals,
                            );
                            agreement.totalDeposited = formatUnits(
                                totalDeposited,
                                agreement.leverage.decimals,
                            );

                            if (totalDeposited === 0n) {
                                agreement.poolUtilization = '0';
                            } else {
                                agreement.poolUtilization = Math.min(
                                    (Number(agreement.totalBorrowed) /
                                        Number(agreement.totalDeposited)) *
                                        100,
                                    100,
                                ).toFixed(2);
                            }

                            Object.assign(agreement, agreementDetails);

                            agreement.totalDepositThreshold = formatUnits(
                                metadata.totalDepositThreshold,
                                agreement.leverage.decimals,
                            );

                            agreement.withdrawable = Math.min(
                                Number(agreement.totalPosition!),
                                Number(agreement.poolBalance!),
                            ).toFixed(8);

                            agreement.interestClaimable = Math.min(
                                Number(agreement.interestAccrued!),
                                Number(agreement.poolBalance!),
                            ).toFixed(8);
                        } catch (e: any) {
                            yield put(
                                agreementErrorAction({ message: e.message || Errors.AGREEMENTS }),
                            );
                            console.log(e);
                        }
                    }),
                ),
            );

            yield put(setAgreementsAction(resultAgreements as Agreement[]));
        }
    } catch (e: any) {
        yield put(agreementErrorAction({ message: e.error || Errors.AGREEMENTS }));
        console.error(e);
    }
}

export function* agreementsWatcher() {
    yield takeLatest(GET_AGREEMENTS, getAgreementsSaga);
}
