import React, { FC } from 'react';
import { AssetWithIcon, ButtonStroke } from '@blink/components';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonSize } from '@blink/components/src/Button/Button.types';
import { shortFormatNumber } from '@blink/components/src/utils';

import { useUserSuppliesRowsStyle } from './UserSuppliesRows.style';
import { showModalAction } from '../../../store/modals/actions';
import { ModalName } from '../../../store/modals/constants';
import { UserSupply } from 'src/store/supplies';
import { Store } from 'src/store';
import { ROLE } from 'src/api/borrow';

type UserSuppliesRowsProps = {
    items: UserSupply[];
};

export const UserSuppliesRows: FC<UserSuppliesRowsProps> = ({ items }) => {
    const classes = useUserSuppliesRowsStyle();
    const dispatch = useDispatch();

    const { agreements } = useSelector((state: Store) => ({
        agreements: state.agreements.agreements,
    }));

    if (!items) {
        return null;
    }

    return (
        <>
            {items.map((item, index) => {
                const agreement = agreements.find(
                    (agr) => agr.role === ROLE.LENDER && agr.address === item.agreementAddress,
                );
                const handleWithdraw = () => {
                    dispatch(
                        showModalAction({
                            modalName: ModalName.LIQUIDITY_MODAL,
                            modalTitle: 'Withdraw Liquidity',
                            modalProps: {
                                balance: item.balance,
                                currentApy: Number(item.apy),
                                totalDeposited: item.totalDeposited,
                                totalDepositThreshold: shortFormatNumber({
                                    amount: Number(item.totalDepositThreshold),
                                }),
                                isWithdraw: true,
                                agreementAddress: item.agreementAddress,
                                tokenAddress: item.tokenAddress,
                                tokenSymbol: item.tokenSymbol,
                                tokenDecimals: item.tokenDecimals,
                                interestClaimable: agreement!.interestClaimable,
                                totalPosition: agreement!.totalPosition,
                                interestAccrued: agreement!.interestAccrued,
                                poolUtilization: agreement!.poolUtilization,
                                poolBalance: agreement!.poolBalance,
                            },
                        }),
                    );
                };
                return (
                    <tr key={index} className={classes.tr}>
                        <td className={classes.firstTd}>{item.agreementTitle}</td>
                        <td>
                            <AssetWithIcon assetName={item.tokenSymbol || 'DAI'} />
                        </td>
                        <td>
                            <div>{item.balance}</div>
                        </td>
                        <td>{item.apy}%</td>
                        <td>
                            <div className={classes.rewards}>
                                <div>
                                    {item.reward} {item.tokenSymbol}
                                </div>
                            </div>
                        </td>
                        <td className={classes.buttonTd}>
                            <ButtonStroke
                                size={ButtonSize.md}
                                className={classes.button}
                                isPrimary
                                onClick={handleWithdraw}
                            >
                                Withdraw
                            </ButtonStroke>
                        </td>
                    </tr>
                );
            })}
        </>
    );
};
