import { Api } from '@blink/components';
import { NETWORK } from '@blink/components/src/constants/global';

export const enum BorrowStatusType {
    OPENED = 'opened',
    SUSPENDED = 'suspended',
    REGISTERED = 'registered',
    CLOSED = 'closed',
}

export type Token = {
    id?: number;
    apy?: string;
    totalDeposited?: string;
    address?: string;
    name: string;
    symbol: string;
    balance?: string;
    decimals: number;
    agreementAddress?: string;
    userBalance?: string;
    poolBalance?: string;
};

export enum ROLE {
    BORROWER = 'borrower',
    LENDER = 'lender',
    OWNER = 'owner',
}

export type AgreementBasic = {
    address: string;
    apy: string;
    icon: string;
    leverageTokenId: number;
    role: ROLE;
    title: string;
};

export type Agreement = AgreementBasic &
    AgreementDetails & {
        totalDepositThreshold?: string;
        totalDeposited?: string;
        description?: string;
        leverage: Token;
        collaterals: any[];
        lenders?: string[];
        borrowers?: string[];
        tokens?: string[];
        totalPosition?: string;
        poolBalance?: string;
        withdrawable?: string;
        interestAccrued?: string;
        interestClaimable?: string;
        totalBorrowed?: string;
        poolUtilization?: string;
    };

export type StressTestMatrix = {
    id: number;
    percent: string;
    address: string;
};

export type AgreementDetails = {
    address: string;
    leverageTokenId: number;
    title: string;
    description: string;
    lenders: string[];
    borrowers: string[];
    liquidationFee: string;
    liquidationThreshold: string;
    marginCallThreshold: string;
    stressTestMatrix: StressTestMatrix[];
    apy: string;
    icon: string;
};

export type MaintenanceData = {
    address: string;
    accruedInterest: string;
    stressTested: string;
    riskFactor: string;
};

export type History<T> = {
    channel: string;
    data: T;
    offset: number;
};

export type UserBorrow = {
    address: string;
    leverage: {
        id: number;
        apy: string;
        address: string;
        name: string;
        symbol: string;
        decimals: number;
        amount: string;
    };
    maintenance: MaintenanceData;
    history: History<MaintenanceData>[];
    openedAt: string;
    agreementAddress: string;
    apy?: string;
};

export type GetAssetsToBorrowProps = {
    blockchainName?: string;
    walletAddress?: string;
    status?: BorrowStatusType;
};

export interface Metadata {
    leverage: string;
    apy: number;
    totalDepositThreshold: bigint;
    collaterals: string[];
    lenders: string[];
    borrowers: string[];
}

export interface State {
    tokens: string[];
    operators: string[];
}

export const getTokens = () => {
    return Api.getResponse<Token[]>(`${Api.REACT_APP_ANALYTICS_HTTP_URL}/v1/tokens`);
};

export const getAgreements = () => {
    return Api.getResponse<AgreementBasic[]>(`${Api.ARKIS_PORTAL_HTTP_URL}/v1/agreements`);
};

export const getAgreementDetails = (address: string) => {
    return Api.getResponse<AgreementDetails>(
        `${Api.ARKIS_PORTAL_HTTP_URL}/v1/agreements/${address}`,
    );
};

export const getUserBorrows = ({
    walletAddress,
    status = BorrowStatusType.OPENED,
}: GetAssetsToBorrowProps) => {
    return Api.getResponse<UserBorrow[]>(
        `${Api.REACT_APP_ANALYTICS_HTTP_URL}/v1/margin-accounts?owner=${walletAddress}&status=${status}`,
    );
};

export type UserBorrowRiskFactorGraph = {
    id: number;
    symbol: string;
    amount: string;
    startApy: string;
    currentApy: string;
    blockchainName: NETWORK;
    marginAccountAddress: string;
    status: string;
    historicalRiskFactor: Array<UserBorrowHistoricalRisk>;
};

export type UserBorrowHistoricalRisk = {
    stressTested: string;
    riskFactor: string;
    accruedInterest: string;
    timestamp: number;
};
