import { put, select, call, takeEvery, all, takeLatest } from 'redux-saga/effects';
import { Errors } from '@blink/components/src/constants/errors';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Contract, ethers, formatUnits } from 'ethers';

import { Store } from '../store';
import { userBorrowsErrorAction } from 'src/store/errors/actions';
import {
    Agreement,
    BorrowStatusType,
    getTokens,
    getUserBorrows,
    ROLE,
    Token,
    UserBorrow,
} from 'src/api/borrow';
import {
    setUserBorrowsOpenedAction,
    setUserBorrowsClosedAction,
    setUserBorrowsRegisteredAction,
    setUserBorrowsSuspendedAction,
    setCollaterals,
} from 'src/store/borrows/actions';
import {
    GET_COLLATERALS,
    GET_USER_BORROWS_CLOSED,
    GET_USER_BORROWS_OPENED,
    GET_USER_BORROWS_REGISTERED,
    GET_USER_BORROWS_SUSPENDED,
} from 'src/store/borrows/constants';
import IERCAbi from '../abi/IERCAbi.json';

function* fetchUserBorrows(
    statusToFetch: BorrowStatusType,
    successAction: (arg0: UserBorrow[]) => any,
) {
    const status = statusToFetch;
    try {
        const { walletAddress, agreements } = yield select((state: Store) => ({
            walletAddress: state.wallets.active.address,
            agreements: state.agreements.agreements,
        }));

        const userBorrows: UserBorrow[] = yield call(getUserBorrows, {
            walletAddress,
            status,
        });

        if (!userBorrows || userBorrows.length === 0 || !agreements || agreements.length === 0) {
            yield put(successAction([] as UserBorrow[]));
            return;
        }

        userBorrows.forEach((item) => {
            const agr = agreements.find((agreement: Agreement) => {
                return (
                    agreement.address === item.agreementAddress && agreement.role === ROLE.BORROWER
                );
            });

            if (!agr) {
                item.apy = 'N/A';
            } else {
                item.apy = agr.apy;
            }
        });

        yield put(successAction(userBorrows as UserBorrow[]));
    } catch (e: any) {
        yield put(successAction([] as UserBorrow[]));
        yield put(userBorrowsErrorAction({ message: e.message || Errors.USER_BORROWS }));
        console.error(e);
    }
}

function* getUserBorrowsOpenedSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.OPENED, setUserBorrowsOpenedAction);
}

function* getUserBorrowsSuspendedSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.SUSPENDED, setUserBorrowsSuspendedAction);
}

function* getUserBorrowsRegisteredSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.REGISTERED, setUserBorrowsRegisteredAction);
}

function* getUserBorrowsClosedSaga() {
    yield call(fetchUserBorrows, BorrowStatusType.CLOSED, setUserBorrowsClosedAction);
}

function* getCollateralsSaga(action: { type: string; payload: string }): Generator<any, any, any> {
    const agreementAddress = action.payload;

    try {
        const {
            type,
            blockchainName,
            walletAddress,
            agreements,
        }: { type: any; blockchainName: any; walletAddress: string; agreements: Agreement[] } =
            yield select((state: Store) => ({
                type: state.wallets.active.type,
                blockchainName: state.wallets.network,
                walletAddress: state.wallets.active.address,
                agreements: state.agreements.agreements,
            }));

        const agreementsByAddress = agreements.filter(
            (agreement) =>
                agreement.address === agreementAddress && agreement.role === ROLE.BORROWER,
        )[0];

        const tokenAddresses = agreementsByAddress.collaterals;

        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;

        if (!wallet) {
            yield put(userBorrowsErrorAction({ message: Errors.ADD_WALLETS }));
        }

        if (wallet) {
            const walletProvider: any = yield wallet.getProvider();

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );
            const signer: ethers.Signer = yield provider.getSigner();

            const tokens: Token[] = yield call(getTokens);

            const tokensByAddresses = tokens.filter((token) =>
                tokenAddresses.includes(token.address as string),
            );

            const tokenBalances = yield all(
                tokensByAddresses.map((token) =>
                    call(function* () {
                        try {
                            const tokenContract = new Contract(
                                token.address as string,
                                IERCAbi,
                                signer,
                            );

                            const balance = yield tokenContract.balanceOf(walletAddress);

                            token.balance = formatUnits(balance as string, token.decimals);
                        } catch (e: any) {
                            yield put(
                                userBorrowsErrorAction({ message: e.message || Errors.TOKENS }),
                            );
                            console.log(e);
                        }
                        return token;
                    }),
                ),
            );

            yield put(setCollaterals(tokenBalances as Token[]));
        }
    } catch (e: any) {
        yield put(setCollaterals([]));
        console.error(e);
    }
}

export function* borrowsWatcher() {
    yield takeEvery(GET_USER_BORROWS_OPENED, getUserBorrowsOpenedSaga);
    yield takeEvery(GET_USER_BORROWS_SUSPENDED, getUserBorrowsSuspendedSaga);
    yield takeEvery(GET_USER_BORROWS_REGISTERED, getUserBorrowsRegisteredSaga);
    yield takeEvery(GET_USER_BORROWS_CLOSED, getUserBorrowsClosedSaga);
    yield takeLatest(GET_COLLATERALS, getCollateralsSaga);
}
