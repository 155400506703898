import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { color } from '@blink/components';
import { ReactComponent as CloseIcon } from '@blink/assets/src/icons/close.svg';

import { Store } from '../../store';
import { web3wallet } from '../../hooks/useWalletConnect';
import { useWalletConnectStatus } from './WalletConnectStatus.style';
import { showModalAction } from 'src/store/modals/actions';
import { ModalName } from 'src/store/modals/constants';

export type WalletConnectStatusProps = {
    disconnect?: () => void;
};

export const WalletConnectStatus = ({ disconnect }: WalletConnectStatusProps) => {
    const classes = useWalletConnectStatus();
    const { address, connectedDapp, url } = useSelector((state: Store) => ({
        address: state.wallets.active.address,
        connectedDapp: state.wallets.walletConnect?.connectedDapp?.name,
        url: state.wallets.walletConnect?.connectedDapp?.url,
    }));

    const dispatch = useDispatch();

    if (!web3wallet || !address || !connectedDapp) {
        return null;
    }

    const maDisconnectModalTitle = () => {
        return (
            <div className={classes.header}>
                <div className={classes.holder} />
                <div className={classes.title}>Margin Account Disconnection</div>
            </div>
        );
    };

    const showMADisconnectionModal = () => {
        dispatch(
            showModalAction({
                modalName: ModalName.MA_DISCONNECTION,
                modalTitle: maDisconnectModalTitle() as any,
                modalProps: {
                    disconnectAction: disconnect,
                },
            }),
        );
    };

    return (
        <span className={classes.wrapper}>
            <Tooltip
                overlayClassName={classes.tooltip}
                title={`Wallet connected to ${connectedDapp} with this wallet`}
                color={color.background.primary}
            >
                Connected to{' '}
                <a href={url} target='_blank' className={classes.root} rel='noreferrer'>
                    {connectedDapp}
                </a>
            </Tooltip>
            <Tooltip
                title={`Disconnect ${connectedDapp}`}
                color={color.background.primary}
                overlayClassName={classes.tooltip}
            >
                <button onClick={() => showMADisconnectionModal()} className={classes.closeButton}>
                    <CloseIcon className={classes.closeIcon} />
                </button>
            </Tooltip>
        </span>
    );
};
