import { spacing } from '@blink/components';
import { createUseStyles } from 'react-jss';

export const useUserBorrowsSectionStyle = createUseStyles({
    root: {
        marginTop: spacing['09'],
    },
    cardsWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: spacing['04'],
        marginTop: spacing['06'],
    },
    pages: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: spacing['05'],
    },
    paginationWrapper: {
        float: 'right',
    },
    itemsSelector: {
        width: `60px !important`,
        '& .ant-select-selector': {
            boxShadow: 'none !important',
        },
        '& .ant-select-selection-item': {
            fontSize: 14,
            fontWeight: 'bold',
        },
    },
    borrowRatesListButtons: {
        width: 'fit-content',
        minWidth: 295,
        marginBottom: spacing['08'],
        '& > label': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 0 0',
            '&.ant-radio-button-wrapper-checked': {
                padding: `11px 19px`,
            },
        },
        '& .ant-radio-button-wrapper': {
            height: 44,
            '& > div': {
                display: 'none',
            },
        },
    },
});
