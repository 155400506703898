import React, { FC, useState } from 'react';
import { AssetWithIcon, InputNumberCustom } from '@blink/components';
import { concatClasses } from '@blink/components/src/utils';
import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '@blink/assets/src/icons/infoCircle.svg';

import { useLiquidityAssetInputStyle } from './AssetInputSupply.style';
import { subtractFixedDecimals } from 'src/utils/helpers';

type AssetInputSupplyProps = {
    tokenSymbol: string;
    balance: string;
    value?: string;
    setValue: (value: any) => void;
    disabled?: boolean;
    classes?: Partial<Record<AssetInputSupplyClasses, string>>;
    isSupply?: boolean;
    isBorrow?: boolean;
    isWithdraw?: boolean;
    decimals: number;
    apy?: string;
    interestClaimable?: string;
    totalPosition?: string;
    interestAccrued?: string;
    totalDeposited?: string;
    totalBorrowed?: string;
};

type AssetInputSupplyClasses = 'root';

export const AssetInputSupply: FC<AssetInputSupplyProps> = ({
    value,
    setValue,
    tokenSymbol,
    balance,
    classes,
    disabled,
    isSupply = false,
    isBorrow = false,
    isWithdraw = false,
    decimals,
    apy,
    interestClaimable,
    totalPosition,
    interestAccrued,
    totalDeposited,
    totalBorrowed,
}) => {
    const baseClasses = useLiquidityAssetInputStyle();
    const [isError, setIsError] = useState<boolean>(false);

    const handleMaxClick = () => {
        setValue(balance);
        setIsError(false);
    };

    const handleAmountChange = (value: number | null) => {
        setValue(value);
        const isValueMoreThanBalance = Number(value) > Number(balance);
        setIsError(isValueMoreThanBalance);
    };

    return (
        <>
            <div
                className={concatClasses(
                    baseClasses.root,
                    classes?.root || '',
                    `${isError && 'error'}`,
                )}
            >
                <div className={baseClasses.footer}>
                    <div className={baseClasses.input}>
                        <InputNumberCustom
                            placeholder={'0'}
                            value={value}
                            handleChange={handleAmountChange}
                            disabled={disabled}
                            decimals={decimals}
                        />
                    </div>
                    <div className={baseClasses.inputWrapper}>
                        <AssetWithIcon
                            assetName={tokenSymbol || ''}
                            classes={{ assetName: baseClasses.assetName }}
                        />
                    </div>
                </div>
            </div>
            <div className={baseClasses.infoWrapper}>
                <div className={baseClasses.balanceWrapper}>
                    <div className={baseClasses.title}>
                        {`${isSupply || isBorrow ? 'Max Available:' : 'Withdrawable:'}`}&nbsp;
                    </div>
                    <div className={baseClasses.textBtn} onClick={handleMaxClick}>
                        {isBorrow
                            ? subtractFixedDecimals(totalDeposited!, totalBorrowed!, decimals)
                            : balance}
                    </div>
                    {isWithdraw && (
                        <Tooltip
                            rootClassName={baseClasses.tooltip}
                            placement='bottomLeft'
                            title="Amount available for withdrawal now. If it's less than the total position, a portion has been allocated and is earning interest"
                        >
                            <InfoIcon className={baseClasses.icon} />
                        </Tooltip>
                    )}
                </div>
                {isWithdraw && (
                    <div>
                        <div className={baseClasses.borrowApyWrapper}>
                            <div className={baseClasses.borrowApy}>
                                <div className={baseClasses.title}>Interest Claimable:</div>
                            </div>
                            <div className={baseClasses.borrowApyNumber}>{interestClaimable}</div>
                            <Tooltip
                                rootClassName={baseClasses.tooltip}
                                placement='bottomLeft'
                                title='Claimable Interest: Accrued Interest − Pending Interest'
                            >
                                <InfoIcon className={baseClasses.icon} />
                            </Tooltip>
                        </div>
                    </div>
                )}

                {isSupply && (
                    <div>
                        <div className={baseClasses.borrowApyWrapper}>
                            <div className={baseClasses.borrowApy}>
                                <div className={baseClasses.title}>Supply APY </div>
                            </div>
                            <div className={baseClasses.borrowApyNumber}>{apy}%</div>
                            <Tooltip
                                rootClassName={baseClasses.tooltip}
                                placement='bottomLeft'
                                title="The annual percentage yield you earn from supplying assets to the pool. It adjusts based on the pool's usage and current interest rates."
                            >
                                <InfoIcon className={baseClasses.icon} />
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
            {isWithdraw && (
                <div className={baseClasses.infoWrapper}>
                    <div className={baseClasses.balanceWrapper}>
                        <div className={baseClasses.title}>Total Position: &nbsp;</div>
                        <div className={baseClasses.borrowApyNumber}>{totalPosition}</div>
                    </div>

                    <div className={baseClasses.borrowApyWrapper}>
                        <div className={baseClasses.borrowApy}>
                            <div className={baseClasses.title}>Interest Accrued:</div>
                        </div>
                        <div className={baseClasses.borrowApyNumber}>{interestAccrued}</div>
                        <Tooltip
                            rootClassName={baseClasses.tooltip}
                            placement='bottomLeft'
                            title='Interest Accrued is calculated based on your deposit, pool utilization, APY, and the time period'
                        >
                            <InfoIcon className={baseClasses.icon} />
                        </Tooltip>
                    </div>
                </div>
            )}
        </>
    );
};
