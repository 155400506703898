import React, { FC } from 'react';
import { ReactComponent as NothingSuppliedIcon } from '@blink/assets/src/icons/nothingSupplied.svg';

import { useUserSuppliesStyle } from '../UserSupplies.style';

type UserSuppliesEmpty = {
    customTitle?: string;
    customSubtitle?: string;
    customButtonName?: string;
    icon?: React.ElementType;
};

export const UserSuppliesEmpty: FC<UserSuppliesEmpty> = ({
    customTitle,
    customSubtitle,
    icon: Icon,
}) => {
    const commonClasses = useUserSuppliesStyle();

    return (
        <div className={commonClasses.noSuppliesBlock}>
            {Icon ? <Icon /> : <NothingSuppliedIcon />}
            <div className={commonClasses.noSuppliesTitle}>
                {customTitle ?? 'Nothing supplied yet'}
            </div>
            <div className={commonClasses.noSuppliesSubtitle}>
                {customSubtitle ?? 'Nothing supplied yet. Start supplying by choosing an asset.'}
            </div>
        </div>
    );
};
