import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { Contract, ethers, formatUnits } from 'ethers';
import { AbstractWallet, WalletHelper } from '@blink/components/src/utils';
import { walletMap } from '@blink/components/src/constants/wallet';
import { Errors } from '@blink/components/src/constants/errors';

import { GET_USERS_SUPPLIES } from '../store/supplies/constants';
import { setUsersSupplies } from '../store/supplies/actions';
import { Store } from '../store';
import { Agreement, ROLE } from '../api/borrow';
import AgreementABI from '../abi/AgreementABI.json';
import { usersSuppliesErrorAction } from 'src/store/errors/actions';
import { UserSupply } from 'src/store/supplies';

const PULL_INTERVAL = 10000;

function* getUserSuppliesSaga(): Generator<any, any, any> {
    try {
        const {
            type,
            blockchainName,
            agreements,
            walletAddress,
        }: { type: any; blockchainName: any; agreements: Agreement[]; walletAddress: string } =
            yield select((state: Store) => ({
                type: state.wallets.active.type,
                blockchainName: state.wallets.network,
                walletAddress: state.wallets.active.address,
                agreements: state.agreements.agreements,
            }));

        const wallet: AbstractWallet = walletMap.get(type) as AbstractWallet;
        if (wallet) {
            const walletProvider = yield wallet.getProvider();

            const provider = new ethers.BrowserProvider(
                walletProvider,
                WalletHelper.getNetworkNumber(blockchainName),
            );

            const signer = yield provider.getSigner();

            const userSupplies: UserSupply[] = [];

            for (const agr of agreements) {
                if (agr.address && agr.role === ROLE.LENDER) {
                    try {
                        const agreement = new Contract(agr.address, AgreementABI, signer);
                        const balance = yield agreement.balanceOf(walletAddress);

                        if (balance !== 0n) {
                            userSupplies.push({
                                agreementAddress: agr.address,
                                agreementTitle: agr.title,
                                totalDeposited: agr.totalDeposited,
                                totalDepositThreshold: agr.totalDepositThreshold,
                                balance: formatUnits(balance, agr.leverage.decimals),
                                reward: 'N/A',
                                apy: agr.apy,
                                tokenAddress: agr.leverage.address,
                                tokenDecimals: agr.leverage.decimals,
                                tokenSymbol: agr.leverage.symbol,
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            }

            yield put(setUsersSupplies(userSupplies as UserSupply[]));

            while (userSupplies.length) {
                for (const agr of agreements) {
                    if (agr.address && agr.role === ROLE.LENDER) {
                        try {
                            const agreement = new Contract(agr.address, AgreementABI, signer);
                            const reward = yield agreement.pendingRewards(walletAddress);

                            const interestAccrued = formatUnits(reward, agr.leverage.decimals);

                            agr.interestAccrued = interestAccrued;

                            const supply = userSupplies.find(
                                (supply) => supply.agreementAddress === agr.address,
                            );

                            if (supply) {
                                supply.reward = interestAccrued;
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }

                yield put(setUsersSupplies(userSupplies as UserSupply[]));

                yield delay(PULL_INTERVAL);
            }
        }
    } catch (e: any) {
        yield put(setUsersSupplies([]));
        yield put(usersSuppliesErrorAction({ message: e.message || Errors.USERS_SUPPLIES }));
        console.error(e);
    }
}

export function* suppliesWatcher() {
    yield takeLatest(GET_USERS_SUPPLIES, getUserSuppliesSaga);
}
